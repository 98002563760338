/* Regular weight */
@font-face {
  font-family: 'PT Sans';
  src: url('./fonts/PT_Sans/PTSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Bold weight */
@font-face {
  font-family: 'PT Sans';
  src: url('./fonts/PT_Sans/PTSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

/* Italic style */
@font-face {
  font-family: 'PT Sans';
  src: url('./fonts/PT_Sans/PTSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}


/* Bold Italic style */
@font-face {
  font-family: 'PT Sans';
  src: url('./fonts/PT_Sans/PTSans-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

/* Regular weight */
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Bold weight */
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

/* Italic style */
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto/Roboto-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

/* Bold Italic style */
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

/* Light weight */
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

/* Light Italic style */
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

/* Medium weight */
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

/* Medium Italic style */
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

/* Thin weight */
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto/Roboto-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

/* Thin Italic style */
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto/Roboto-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

/* Black weight */
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

/* Black Italic style */
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto/Roboto-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
/* noto-sans-200 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 200;
  src: url('./fonts/noto-sans-v35-latin-200.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* noto-sans-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/noto-sans-v35-latin-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* noto-sans-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/noto-sans-v35-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* noto-sans-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/noto-sans-v35-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* noto-sans-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/noto-sans-v35-latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* noto-sans-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/noto-sans-v35-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* noto-sans-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/noto-sans-v35-latin-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* noto-sans-900 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 900;
  src: url('./fonts/noto-sans-v35-latin-900.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* Regular weight */
@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway/Raleway-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Bold weight */
@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway/Raleway-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

/* Italic style */
@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway/Raleway-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

/* Bold Italic style */
@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway/Raleway-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

/* ExtraBold weight */
@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway/Raleway-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

/* ExtraBold Italic style */
@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway/Raleway-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

/* ExtraLight weight */
@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway/Raleway-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

/* ExtraLight Italic style */
@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway/Raleway-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

/* Light weight */
@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway/Raleway-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

/* Light Italic style */
@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway/Raleway-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

/* Medium weight */
@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway/Raleway-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

/* Medium Italic style */
@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway/Raleway-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

/* SemiBold weight */
@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway/Raleway-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

/* SemiBold Italic style */
@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway/Raleway-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

/* Thin weight */
@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway/Raleway-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

/* Thin Italic style */
@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway/Raleway-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

/* Arial Regular */
@font-face {
  font-family: 'Arial';
  src: url('./fonts/Arial/ARIAL.TTF') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Arial Bold */
@font-face {
  font-family: 'Arial';
  src: url('./fonts/Arial/ARIALBD.TTF') format('truetype');
  font-weight: bold;
  font-style: normal;
}

/* Arial Italic */
@font-face {
  font-family: 'Arial';
  src: url('./fonts/Arial/ARIALI.TTF') format('truetype');
  font-weight: normal;
  font-style: italic;
}

/* Arial Bold Italic */
@font-face {
  font-family: 'Arial';
  src: url('./fonts/Arial/ARIALBI.TTF') format('truetype');
  font-weight: bold;
  font-style: italic;
}

/* Open Sans Regular */
@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/Open_Sans/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Open Sans Bold */
@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/Open_Sans/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

/* Open Sans Italic */
@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/Open_Sans/OpenSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

/* Open Sans Bold Italic */
@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/Open_Sans/OpenSans-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

/* Open Sans Light */
@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/Open_Sans/OpenSans-Light.ttf') format('truetype');
  font-weight: 300; /* Light weight */
  font-style: normal;
}

/* Open Sans SemiBold */
@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/Open_Sans/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: 600; /* SemiBold weight */
  font-style: normal;
}
