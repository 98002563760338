/* You can add global styles to this file, and also import other style files */

//@use "@angular/material" as mat;
//@include mat.core();

@use "font" as *;
@use "theme_ws" as *;
@use "tailwindcss/base" as *;
@use "tailwindcss/components" as *;
@use "tailwindcss/utilities" as *;

@tailwind base;
@tailwind components;
@tailwind utilities;


html, body {
  min-height: 100%;
}

body {
  margin: 0;
  font-family: Noto Sans, sans-serif;
  color: $darkgrey;
  //background-image: radial-gradient(circle farthest-side at bottom left, #597588 0%, #0D3652 50%, #0D3652 75%, transparent 100%), radial-gradient(circle farthest-side at top right, #597588 0%, #0D3652 50%, #0D3652 75%, #0D3652 100%);
  //background-attachment: fixed;
}


h1 {
  display: block;
  font-size: 1rem;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  color: $primary;
}

h2 {
  display: block;
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

h3 {
  display: block;
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  color: $darkgrey;
  font-family: Noto Sans, sans-serif;
}


.wspdf-active-element {
  outline: 1px solid $accent;
  z-index: 1000 !important;

  .dragHandle {
    background-color: $accent;
  }
}

.wspdf-element__text, .wspdf-element__table {
  &:focus-visible, :focus-visible {
    outline: none;
  }
}

.wspdf-element > div {
  &.cdk-drag-dragging {
    outline: 2px solid $accent;
    background-color: rgba(0, 169, 226, 0.15);
  }
}


.element-settings-title {
  font-size: 1rem;
}

mat-sidenav-container {
  background-color: #f5f5f5;
}

.mat-mdc-snack-bar-container {
  &.info-notification {
    --mdc-snackbar-container-color: rgba(44, 168, 255, 0.8);
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }

  &.success-notification {
    --mdc-snackbar-container-color: rgba(24, 206, 15, 0.8);
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }

  &.error-notification {
    --mdc-snackbar-container-color: rgba(255, 54, 54, 0.8);
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }

  &.warning-notification {
    --mdc-snackbar-container-color: rgba(255, 178, 54, 0.8);
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
}

:root {
  --mdc-shape-small: 10px;
  --mdc-filled-button-container-color: #00a2df;
  --mdc-filled-button-label-text-color: #fff;

  mat-icon.mat-icon {
    display: inline-block;
    position: relative;
    vertical-align: top;
    font-size: 1.125rem;
    height: 1.125rem;
    width: 1.125rem;
  }
}
