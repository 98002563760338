@use '@angular/material' as mat;
@use '@material-symbols/font-400/outlined' as *;

@include mat.elevation-classes();
@include mat.app-background();

$primary: #0d3652;
$accent: #00a9e2;
$darkgrey: #545C60;


$ws-primary-palette: (
  50 : #e2e7ea,
  100 : #b6c3cb,
  200 : #869ba9,
  300 : #567286,
  400 : #31546c,
  500 : #0d3652,
  600 : #0b304b,
  700 : #092941,
  800 : #072238,
  900 : #031628,
  A100 : #63a5ff,
  A200 : #3087ff,
  A400 : #006afc,
  A700 : #0060e2,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$ws-accent-palette: (
  50 : #e0f5fc,
  100 : #b3e5f6,
  200 : #80d4f1,
  300 : #4dc3eb,
  400 : #26b6e6,
  500 : #00a9e2,
  600 : #00a2df,
  700 : #0098da,
  800 : #008fd6,
  900 : #007ecf,
  A100 : #f6fbff,
  A200 : #c3e5ff,
  A400 : #90ceff,
  A700 : #77c3ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$white-palette: (
  500: #ffffff,
  contrast: (
    500: #000000,
  )
);

$ws-primary: mat.m2-define-palette($ws-primary-palette, 500);
$ws-accent: mat.m2-define-palette($ws-accent-palette, 500);
$ws-white: mat.m2-define-palette($white-palette, 500, 500, 500);

// The "warn" palette is optional and defaults to red if not specified.
//$my-warn: mat.define-palette(mat.$red-palette);


$ws-light-theme: mat.m2-define-light-theme((
  color: (
    primary: $ws-primary,
    accent: $ws-accent,
    white: $ws-white,
  ),
  typography: mat.m2-define-typography-config(),
  density: -1,
));
//$ws-dark-theme: mat.define-dark-theme((
//  color: (
//    primary: $ws-accent,
//    accent: $ws-primary
//  ),
//  typography: mat.define-typography-config(),
//  density: -1,
//));

@include mat.all-component-themes($ws-light-theme);

.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-label-text-color: white;
}


:root {
  // mat-chip
  .mat-mdc-standard-chip {
    --mdc-chip-container-shape-radius: 10px;
    --mdc-chip-elevated-container-color: #{$accent};
    --mdc-chip-label-text-color: white;
    --mdc-chip-with-trailing-icon-trailing-icon-color: white;
    --mdc-chip-container-height: 42px;

    .mat-mdc-chip-remove {
      opacity: 1;
    }
  }


  .mat-mdc-slide-toggle {
    --mat-switch-with-icon-handle-size: 16px;
    --mat-switch-unselected-handle-size: 16px;
    --mat-switch-selected-handle-size: 16px;
    --mat-switch-pressed-handle-size: 16px;

    &.mat-accent {
      --mdc-switch-selected-track-color: #{$accent};
      --mdc-switch-selected-hover-track-color: #{$accent};
      --mdc-switch-selected-focus-track-color: #{$accent};
      --mdc-switch-selected-pressed-track-color: #{$accent};
      --mdc-switch-selected-handle-color: #FFF;
      --mdc-switch-selected-hover-handle-color: #FFF;
      --mdc-switch-selected-focus-handle-color: #FFF;
      --mdc-switch-selected-pressed-handle-color: #FFF;
    }
  }
}

//@media (prefers-color-scheme: dark) {
//  // If the user's system preference is dark, then use the dark theme.
//  // Use the `-color` mixins to only apply color styles without reapplying the same
//  // typography and density styles.
//  @include mat.core-color($ws-light-theme);
//  @include mat.button-color($ws-dark-theme);
//}
